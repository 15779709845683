html,
body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    background-color: rgb(255, 255, 255);
}

.root {
    filter: invert(100%);
}

.container {
    margin: 80px auto 0 auto;
    max-width: 1200px;
}

.nav-container {
    margin: auto;
}

a {
    text-decoration: none;
}

.dark {
    background-color: #333;
    color: #fff;
}

.light {
    background-color: #fff;
    color: #333;
}

.dark-grey-table {
    background-color: #333;
    /* dark grey */
    color: #fff;
    /* white */
}

.dark-grey-expansion-panel {
    background: #333;
    background-color: #333;
    /* dark grey */
    color: #fff;
    /* white */
}

.white-link {
    color: #fff;
    /* white */
}

.scroll-to-top-btn {
    position: fixed;
    right: 20px;
    bottom: 20px;
    display: none;
    background-color: #a6a6a6;
    color: #fff;
    border-color: transparent;
    padding: 10px 15px;
    border-radius: 50%;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.scroll-to-top-btn.show {
    display: block;
}

.scroll-to-top-btn:hover {
    background-color: #126149;
}

.container.light {
    background-color: #fff;
    color: #333;
}

.container.dark {
    background-color: #333;
    color: #fff;
}

body.light {
    background-color: #fafafa;
    color: #000000;
}

body.dark {
    background-color: #121212;
    color: #ffffff;
}

.code-style {
    background-color: #f0f0f0;
    /* Use any shade of grey you like */
    padding: 0.2em 0.4em;
    margin: 0;
    font-size: 85%;
    border-radius: 6px;
}